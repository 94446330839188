<template>
  <v-card outlined id="campaign">
    <ConfirmDelete
      :dialog="dialog"
      :item="mine"
      @delete="rundeleteFn($event)"
      @dismiss="dismissFn($event)"
    />

    <showBanners
      :dialog="modalShow"
      :item="mine"
      @submitBanners="submitCampaignBanners($event)"
      @dismiss="dismissFn($event)"
    />

    <v-card-title>
      <div class="d-flex">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex">
        <v-select
          v-if="isDirectDeals"
          :items="deals_filters"
          outlined
          dense
          append-icon="mdi-chevron-down"
          label="Add filter"
          v-model="deals_filter"
          class="ml-3"
          hide-details
        ></v-select>

        <v-select
          v-if="isYoutube"
          :items="youtube_filters"
          outlined
          dense
          append-icon="mdi-chevron-down"
          label="Add filter"
          v-model="youtube_filter"
          class="ml-3"
          hide-details
        ></v-select>
        <!-- <v-spacer v-if="isYoutube || isDirectDeals"></v-spacer> -->
        <v-select
          v-if="
            !isFacebook &&
            !isFacebookDb &&
            !isDirectDeals &&
            !isYoutube &&
            !isHome &&
            superAdmin
          "
          :items="agencies"
          v-model="selected_agency"
          return-object
          item-text="agency_name"
          outlined
          dense
          append-icon="mdi-chevron-down"
          label="Select Agency"
          class="ml-3"
          hide-details
        >
        </v-select>
        <v-select
          v-if="
            !isFacebook &&
            !isFacebookDb &&
            !isDirectDeals &&
            !isYoutube &&
            !isHome &&
            superAdmin
          "
          :items="selected_agency ? fetched_advertisers : advertisers"
          v-model="selected_advertiser"
          item-text="client_name"
          return-object
          outlined
          dense
          append-icon="mdi-chevron-down"
          label="Select Advertiser"
          class="ml-3"
          hide-details
        ></v-select>
        <!-- <v-select
          v-if="!isFacebook && !isDirectDeals && !isYoutube && !isHome" 
          :items="campaignList" 
          outlined dense append-icon="mdi-chevron-down" 
          label="Select Campaign"
          class="ml-2">
          </v-select> -->
      </div>
      <!-- <v-spacer></v-spacer> -->
      <div
        v-if="campaigns && $route.name != 'campaigns' && !isHome"
        class="d-flex ml-3"
      >
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="generate">
              <v-list-item-title
                ><v-icon small class="me-2">mdi-export</v-icon
                >Export</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      v-if="campaigns"
      :headers="headers"
      :items="campaigns"
      :search="search"
      :page.sync="page"
      hide-default-footer
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      @click:row="viewReport"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }" v-if="isFacebook || isHome">
        <span class="d-flex">
          <v-icon small :color="setStatusColor(item.effective_status)"
            >mdi-circle-medium</v-icon
          >
          <span class="ml-2">
            <router-link
              :to="{
                name: 'facebook-campaign',
                params: {
                  id: item.id,
                },
              }"
            >
              <span
                :class="
                  item.effective_status === 'ARCHIVED'
                    ? 'success--text font-weight-bold'
                    : 'primary--text font-weight-bold'
                "
              >
                {{ item.name }}
              </span>
            </router-link>
            <br />
            <div :class="setClass(item.effective_status)">
              <span v-if="item.effective_status === 'ACTIVE'"
                >Running Since:
                {{ new Date(item.start_time).toDateString() }}</span
              >
              <span v-else>{{ item.effective_status }}</span>
              <span v-if="item.effective_status === 'WITH_ISSUES'"
                >Has Issues</span
              >
              <span v-if="item.effective_status === 'IN_PROCESS'"
                >Waiting for approval</span
              >
              <!-- <span v-if="item.effective_status === 'WITH_ISSUES'"
                >CAMPAIGN SUSPENDED DUE TO INSUFFICIENT BALANCE</span
              > -->
              <span v-if="item.effective_status === 'ARCHIVED'"
                >Finished 02 Sep 2021</span
              >
            </div>
          </span>
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }" v-else>
        <span class="d-flex">
          <v-icon
            small
            :color="setStatusColor(item.effective_status || item.status)"
            v-if="!isDirectDeals"
          >
            mdi-circle-medium</v-icon
          >
          <span :class="isDirectDeals ? '' : 'ml-1'">
            <span
              v-if="isDsp"
              :class="
                isYoutube
                  ? 'info--text font-weight-bold'
                  : item.effective_status === 'ARCHIVED' ||
                    item.status == 'finished'
                  ? 'success--text font-weight-bold'
                  : 'secondary--text font-weight-bold'
              "
            >
              <router-link
                :to="{
                  name: 'view-dsp-campaign',
                  params: {
                    id: item.id,
                  },
                }"
              >
                {{ item.name }}
              </router-link>
            </span>

            <span
              v-else
              :class="
                isYoutube
                  ? 'info--text font-weight-bold'
                  : item.effective_status === 'ARCHIVED' ||
                    item.status == 'finished'
                  ? 'success--text font-weight-bold'
                  : 'secondary--text font-weight-bold'
              "
            >
              {{ item.name }}
            </span>

            <br v-if="isDirectDeals" />
            <span v-if="isDirectDeals">{{ item.id }}</span>
            <br />
            <h6 :class="setClass(item.status)">
              <span v-if="item.status === 'running'"
                >Running since:
                {{ new Date(item.start_time).toDateString() }}</span
              >
              <span v-if="item.status === 'rejected'">Has Issues</span>
              <span v-if="item.status === 'waiting'">Waiting for approval</span>
              <!-- <span v-if="item.effective_status === 'WITH_ISSUES'"
                >CAMPAIGN SUSPENDED DUE TO INSUFFICIENT BALANCE</span
              > -->
              <span v-if="item.status === 'finished'"
                >Finihsed: {{ item.end_date }}</span
              >
            </h6>
          </span>
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.objective="{ item }">
        <span>{{ styleString(item.objective) }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.total_budget="{ item }">
        <span>{{ styleString(Number(item.total_budget).toFixed(2)) }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.bid_strategy="{ item }">
        <span v-if="item.bid_strategy">{{
          styleString(item.bid_strategy)
        }}</span>
        <span v-else>None</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.budget_remaining="{ item }">
        <span class="font-weight-bold"
          >USD {{ (item.budget_remaining / 100).toFixed(2) }}</span
        >
      </template>

      <!-- PROGRAMATIC -->

      <!-- eslint-disable-next-line -->
      <template v-slot:item.activate_time="{ item }">
        <span> <span>Start: </span>{{ item.start_date }}</span
        ><br />
        <span><span>End: </span>{{ item.end_date }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-center">
          <v-btn
            @click="showBanners(item)"
            class="mt-2 mr-1"
            outlined
            x-small
            color="warning"
          >
            banners
          </v-btn>

          <v-tooltip top color="grey lighten-1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon small>{{
                  item.status === "runnable" ? "mdi-pause" : "mdi-play"
                }}</v-icon>
              </v-btn>
            </template>
            <span>
              {{
                item.status === "runnable" ? "Pause Campaign" : "Run Campaign"
              }}
            </span>
          </v-tooltip>

          <v-tooltip top color="grey lighten-1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="
                  $router.push({
                    name: 'edit-dsp-campaign',
                    params: { id: item.id },
                  })
                "
                icon
              >
                <v-icon small> mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Campaign</span>
          </v-tooltip>

          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="openModal(item)" icon>
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Campaign</span>
          </v-tooltip>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.delivery_progress="{ item }">
        {{ item.delivery_progress.delivered }} delivered
        <br />
        <span class="text--disabled">{{ item.delivery_progress.days }}</span>
      </template>

      <!-- Youtube -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.avg_cpv="{ item }">
        {{ item.avg_cpv || "__" }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.avg_cpm="{ item }">
        {{ item.avg_cpm || "__" }}
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions
      class="d-flex justify-space-between align-center pa-4"
      v-if="campaigns"
    >
      <div>
        <v-row align="center">
          <v-col>
            <span v-if="isDsp()" class="caption"
              >Page: {{ dsp_pagination.page }} of
              {{ dsp_pagination.totalItems }}</span
            >
            <span v-else class="caption"
              >Page: {{ page }} of {{ pageCount }}</span
            >
          </v-col>

          <v-col v-if="isDsp() && campaigns.length">
            <v-select
              v-model="filter_params.per_page"
              outlined
              :items="items"
              label="Rows Per Page"
              dense
            ></v-select>
          </v-col>
        </v-row>
      </div>

      <v-pagination
        v-if="isDsp()"
        v-model="dsp_pagination.page"
        :length="dsp_pagination.totalItems"
        :total-visible="10"
        color="primary"
        class="custom"
        @input="handlePageChange"
      ></v-pagination>

      <v-pagination
        v-else
        v-model="page"
        :length="pageCount"
        color="primary"
        class="custom"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ConfirmDelete from "../ConfirmDelete.vue";
import showBanners from "../Programmatic/ShowCampaignBannersModal.vue";

let date = new Date();
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  props: {
    campaigns: {
      type: Array,
    },
    paginated_campaigns: {
      type: Object,
      default: null,
    },
    headers: {
      type: Array,
    },
    advertisers: {
      type: Array,
    },
    agencies: {
      type: Array,
    },
    fetched_advertisers: {
      type: Array,
    },
    dsp_pagination: {
      type: Object,
    },
  },
  components: {
    ConfirmDelete,
    showBanners,
  },
  data() {
    return {
      dialog: false,
      modalShow: false,
      mine: {},
      selected_agency: null,
      selected_advertiser: null,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deals_filters: ["Preferred Deal", "Private Auction"],
      deals_filter: "",
      youtube_filters: ["Ended", "Eligible"],
      youtube_filter: "",
      show: false,
      date: date,
      isFacebook: false,
      isDirectDeals: false,
      isYoutube: false,
      isHome: false,
      isFacebookDb: false,
      search: "",
      agency: "",
      campaign: "",
      advertiser: "",
      display: 10,
      items: [10, 25, 50, 200, 250],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [1, 5, 10, 25, 50],
        total: 1,
        to: 1,
        from: 1,
        firstPage: 1,
        lastPage: 1,
        nextPage: 0,
        previousPage: 0,
      },
      filter_params: {
        per_page: 10,
        page: 1,
      },
    };
  },
  methods: {
    handlePageChange(value) {
      this.dsp_pagination.page = value;
      this.filter_params.page = value;
      this.$emit("triggerPageChange", value);
    },
    viewReport(item) {
      this.$emit("viewReport", item.id);
    },
    isDsp() {
      return this.$route.name === "campaigns";
    },
    async showBanners(item) {
      this.modalShow = !this.modalShow;
      this.mine = await item;
    },
    submitCampaignBanners(campaign_id) {
      this.$emit("submitBanners", campaign_id);
    },
    rundeleteFn(id) {
      // Delete campaign
      this.$emit("deleteCampaign", id);
    },
    dismissFn() {
      this.dialog = false;
      this.modalShow = false;
    },
    async openModal(item) {
      this.dialog = !this.dialog;
      // this.modalOpen = true;
      this.mine = await item;
    },
    setStatusColor(status) {
      if (status === "WITH_ISSUES") return "error";
      if (status === "rejected") return "error";
      if (status === "suspended") return "error";
      if (status === "IN_PROCESS") return "warning";
      if (status === "waiting") return "warning";
      if (status === "PAUSED") return "warning";
      if (status === "ACTIVE") return "success";
      if (status === "running") return "success";
      if (status === "runnable") return "success";
      if (status === "ARCHIVED") return "secondary";
      if (status === "finished") return "secondary";
      if (status === "Eligible") return "success";
      if (status === "Ended") return "secondary";
      if (status === "offline") return "secondary";
    },
    setClass(status) {
      if (status === "WITH_ISSUES") return "error--text";
      if (status === "IN_PROCESS") return "warning--text";
      if (status === "PAUSED") return "warning--text";
      if (status === "ACTIVE") return "success--text";
      if (status === "ARCHIVED") return "success-text";
    },
    styleString(data) {
      return data.replaceAll("_", " ");
    },
    generate() {
      let docDefinition = {
        content: [
          {
            text: "CAMPAIGNS",
            fontSize: 20,
            bold: true,
            alignment: "center",
            decoration: "underline",
            color: "skyblue",
          },
          {
            text: "Disclaimer!",
            style: "sectionHeader",
          },
          {
            columns: [
              [{ text: "This is live data." }],
              [
                {
                  text: `Date: ${new Date().toLocaleString()}`,
                  alignment: "right",
                },
                {
                  text: `Serial : ${(Math.random() * 1000).toFixed(0)}`,
                  alignment: "right",
                },
              ],
            ],
          },
          {
            columns: [
              [
                {
                  qr: "Mediapal",
                  fit: "50",
                  style: "sectionHeader",
                },
              ],
            ],
          },
          {
            table: {
              headerRows: 1,
              widths: ["*", "auto", "auto", "auto"],
              body: [
                ["Ad", "Status", "Objective", "Amount Remaining"],
                ...this.campaigns.map((c) => [
                  c.name,
                  c.effective_status,
                  c.objective,
                  c.budget_remaining,
                ]),
                [{ text: "Total Amount", colSpan: 3 }, {}, {}, {}],
              ],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: "underline",
            fontSize: 14,
            margin: [0, 15, 0, 15],
          },
        },
      };
      pdfMake.createPdf(docDefinition).print();
    },
  },
  async mounted() {
    this.$route.name == "facebook"
      ? (this.isFacebook = true)
      : this.$route.name == "direct-deals"
      ? (this.isDirectDeals = true)
      : this.$route.name == "youtube-campaigns"
      ? (this.isYoutube = true)
      : null;

    this.$route.name == "Facebook Dashboard" ? (this.isHome = true) : null;
    this.$route.name == "Facebook Dashboard"
      ? (this.isFacebookDb = true)
      : null;
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    currency() {
      let data = this.$store.state.ad_account;
      if (data) {
        return data.currency;
      }
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  watch: {
    deals_filter() {
      this.$emit("filterDealType", this.deals_filter);
    },
    youtube_filter() {
      this.$emit("filterYoutubeCampaignType", this.youtube_filter);
    },
    selected_agency() {
      this.$emit("filterByAgency", this.selected_agency);
    },
    selected_advertiser() {
      this.$emit("filterByAdvertiser", this.selected_advertiser);
    },
    filter_params: {
      handler: function () {
        this.itemsPerPage = this.filter_params.per_page;
        this.$emit("filterParams", this.filter_params);
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>
