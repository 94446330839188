<template>
  <campaigns-table
    :headers="headers"
    :campaigns="youtube_campaigns"
    @filterYoutubeCampaignType="filterYoutubeCampaignType($event)"
  />
</template>

<script>
import CampaignsTable from "../../components/Tables/CampaignsTable.vue";

export default {
  data: () => ({
    youtube_campaigns: [],
    headers: [
      {
        text: "Campaign",
        align: "start",
        sortable: false,
        value: "name",
        width: "300px",
      },
      { text: "Budget", value: "budget", width: "120px" },
      { text: "Status", value: "status", width: "120px" },
      {
        text: "Campaign type",
        value: "type",
        width: "140px",
      },
      {
        text: "Avg. CPV",
        value: "avg_cpv",
        width: "110px",
      },
      {
        text: "Avg. CPM",
        value: "avg_cpm",
        width: "110px",
      },
      {
        text: "Cost",
        value: "cost",
        width: "90px",
      },
      {
        text: "Impressions",
        value: "impressions",
        width: "120px",
      },
    ],
  }),
  mounted() {
  },
  components: {
    CampaignsTable,
  },
  methods: {
    filterYoutubeCampaignType(status) {
      this.youtube_campaigns = this.data.youtube_campaigns.filter(
        (campaign) => campaign.status === status
      );
    },
  },
};
</script>